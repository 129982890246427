import { ScreenContent, ScreenHeader } from '@cae/cobalt-react';
import {
  DashboardCarousel,
  DashboardGreeting,
  DashboardTabs,
} from '@/features/dashboard';
import FirstTimeUser from '@/features/first-time-user/FirstTimeUser';
import './HomePage.css';
import RoleBasedDashboardAlert from '@/features/dashboard/components/role-based-dashboard-alert/RoleBasedDashboardAlert';
import RoleBasedTableWrapper from '@/features/dashboard/components/role-base-wrapper/RoleBasedWrapper';
import { useUserInfo } from '@/contexts/userInfo';
import LoaderWithLabel from '@/shared/components/loader/Loader';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import AlertDashboardCarousel from '@/features/dashboard/components/alert-carousel/AlertDashboardCarousel';
import { useCurrentRoles } from '@/shared/hooks/useCurrentRole';
import { checkRoleMatch } from '@/shared/components/check-internal-roles/CheckInternalRoles';
import AccessPassWidget from '@/features/accesspass-mfe/AccessPassWidget';

function HomePage(): JSX.Element {
  const [isSpinnerDisplayed] = useState(false);
  const {
    isInternalUser,
    selectedOrganization,
    selectedOrganizationId,
    isVisitorOnly,
  } = useUserInfo();
  const navigate = useNavigate();
  const {
    customerorganizationswitcher,
    HomepageNewAlertCardsWebApp,
    accessPageEnabledWebApp,
  } = useFlags();
  const sfContact = sessionStorage.getItem('sfmastercontactid');
  const currentRoles = useCurrentRoles();
  const { instructormyschedule } = useFlags();
  const { instructorexperiencebackbutton } = useFlags();
  const handleAccessPassKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      navigate('/accesspass');
    }
  };
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (instructorexperiencebackbutton) {
      const handlePopState = (): void => {
        setRefresh(prev => !prev); // Toggle the refresh state to trigger a re-render
      };

      window.addEventListener('popstate', handlePopState);

      return (): void => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [instructorexperiencebackbutton]);

  useEffect((): void => {
    if (
      instructorexperiencebackbutton &&
      currentRoles &&
      currentRoles.length === 1 &&
      checkRoleMatch(currentRoles, 'CAE Instructor') &&
      isInternalUser
    ) {
      if (instructormyschedule) {
        navigate('/schedule');
      }
    }
  }, [
    currentRoles,
    isInternalUser,
    instructormyschedule,
    navigate,
    refresh,
    instructorexperiencebackbutton,
  ]);

  if (
    !instructorexperiencebackbutton &&
    currentRoles &&
    currentRoles.length === 1 &&
    checkRoleMatch(currentRoles, 'CAE Instructor') &&
    isInternalUser
  ) {
    if (instructormyschedule) {
      navigate('/schedule');
    }
  }

  if (
    selectedOrganizationId === null &&
    !isVisitorOnly &&
    !customerorganizationswitcher &&
    !isInternalUser
  ) {
    return <Navigate to="/org-selector" />;
  }
  if (sfContact && sfContact.length > 0) {
    navigate('/sf-connect/home');
  }

  if (selectedOrganization === undefined && !isVisitorOnly) {
    return <LoaderWithLabel title="Loading organization" />;
  }

  return (
    <section className="page page-dashboard">
      {!isSpinnerDisplayed && (
        <div className="page-wrapper">
          <ScreenHeader className="screen-header--dashboard" elevation="base">
            <DashboardGreeting isVisitor={isVisitorOnly} />
          </ScreenHeader>

          {!isVisitorOnly && (
            <div className="card-list" data-testid="reservationsAlertCards">
              {HomepageNewAlertCardsWebApp ? (
                <RoleBasedDashboardAlert />
              ) : (
                <AlertDashboardCarousel
                  organizationId={selectedOrganizationId ?? ''}
                />
              )}
            </div>
          )}

          <ScreenContent>
            <div className="primary-content">
              {!isVisitorOnly && <RoleBasedTableWrapper />}
              {accessPageEnabledWebApp && isVisitorOnly && (
                <button
                  onClick={() => navigate('/accesspass')}
                  onKeyDown={handleAccessPassKeyDown}
                  className="w-full border-none bg-transparent p-0 m-0 text-left"
                  aria-label="Access Pass Details"
                >
                  <AccessPassWidget />
                </button>
              )}
            </div>
            <aside>
              {!isVisitorOnly && <DashboardTabs />}
              <div className="card-carousel">
                <DashboardCarousel isVisitor={isVisitorOnly} />
              </div>
            </aside>
          </ScreenContent>
        </div>
      )}
      <Outlet />
      {!isInternalUser && <FirstTimeUser />}
    </section>
  );
}

export default HomePage;
